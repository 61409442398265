import onLoadAsync from 'shared/on_load_async';

window.imageMapResize = require('image-map-resizer');

onLoadAsync(() => {
  // Resize the image areas to adapt them to the image size
  document.querySelectorAll('map').forEach((mapElement) => {
    const imageWith = Number(mapElement.dataset.imageWidth);
    const originalImageWith = Number(mapElement.dataset.originalImageWidth);
    const imagesSizeRatio = imageWith / originalImageWith;
    mapElement.querySelectorAll('area').forEach((areaElement) => {
      const currentCoords = areaElement.getAttribute('coords');
      const scaledCoords = currentCoords.split(',')
        .map((value) => Number(value) * imagesSizeRatio).join(',');
      areaElement.setAttribute('coords', scaledCoords);
    });
  });

  // eslint-disable-next-line no-undef
  imageMapResize();
});
