/* eslint-disable import/no-dynamic-require */
/* eslint no-console:0 */
const images = require.context('../../assets/images', true);
// eslint-disable-next-line no-unused-vars
const imagePath = (name) => images(name, true);

require('../../assets/stylesheets/application.sass');

require('shared/components/accordion');
require('shared/components/modal');
require('shared/components/burger_menu');
require('shared/slider');

require('../carte_simple_el');
require('../filtre_recherche');
require('../tracking_events');
require('../homepage');
require('../navigation_mobile');
require('../navigation_desktop');
